@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  font-family: 'Titillium Web', sans-serif;
  color: var(--bs-gray-300);
  overflow-x: hidden;
}

.col-md-4 {
  padding: 12px !important;
}

.card {
  background: var(--bs-gray-300);
}

.card#search:hover {
  transform: scale(1);
  box-shadow: inherit;
}

main .card {
  transition: all 0.1s ease-in-out;
  animation: showCard ease 0.3s;
}

main .card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@keyframes showCard {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form-control:focus,
.form-control:active {
  outline: none;
  box-shadow: none;
}

li {
  list-style: none;
}

.btn {
  border: 1px solid var(--bs-gray-900) !important;
  animation: fadeIn ease 0.3s;
}

.btn:hover {
  background: var(--bs-gray-300) !important;
  color: var(--bs-gray-900) !important;
  border: 1px solid var(--bs-gray-900) !important;
}
